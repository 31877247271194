import classNames from 'classnames';
import classnames from 'classnames';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { formatDateTime } from '_utils';
import { routes } from '../../../routes';
import { ReactComponent as CaseIcon } from '_assets/icons/case.svg';
import { ReactComponent as TargetIcon } from '_assets/icons/target.svg';
import { TooltipContentV2, TooltipTriggerV2, TooltipV2 } from '_atoms';
import { SearchResultsTooltipContent } from './SearchResultsTooltipContent';
import { propsToStatusIcon } from './utils';
import { useTranslation } from 'react-i18next';

interface SharedRowProps {
    showTypeIcon?: boolean;
    name: string;
    createdAt: string;
    caseId: string;
    targetId?: string;
    creatorName: string;
    creatorEmail: string;
}

interface CaseRowProps extends SharedRowProps {
    caseType?: string | null;
    type: 'case';
}

interface TargetRowProps extends SharedRowProps {
    targetStatus: string;
    type: 'target';
}

export type SearchResultsRowProps = CaseRowProps | TargetRowProps;

const iconClassName = 'h-6 w-6';
const tooltipIconClassName = 'h-5 w-5';

export function SearchResultsRow(
    props: SearchResultsRowProps,
): React.ReactElement {
    const { t } = useTranslation();
    const { name, createdAt, showTypeIcon, caseId, targetId, type } = props;

    const statusIconData = propsToStatusIcon(props);

    const statusIcon = statusIconData ? (
        <statusIconData.icon className={tooltipIconClassName} />
    ) : null;

    const coloredStatusIcon = statusIconData ? (
        <statusIconData.icon
            className={classnames(iconClassName, statusIconData.color)}
        />
    ) : null;

    const TypeIcon = props.type === 'case' ? CaseIcon : TargetIcon;

    const typeIcon = <TypeIcon className={iconClassName} />;
    const tooltipTypeIcon = <TypeIcon className={tooltipIconClassName} />;

    const to =
        type === 'case'
            ? generatePath(routes.caseShow.path, {
                  caseId,
              })
            : generatePath(routes.targetShow.path, {
                  caseId,
                  targetId,
              });

    const status =
        'targetStatus' in props
            ? t(`targetStatus.${props.targetStatus}`)
            : t(`caseTypes.${props.caseType}`);

    return (
        <TooltipV2 openDelay={500} placement="right-start">
            <TooltipTriggerV2 className="even:bg-neutral-200">
                <Link
                    className={classNames(
                        'flex p-4 text-left justify-between items-center even:bg-neutral-200 hover:bg-blue-100 transition-colors duration-300 focus:outline-none focus:ring-1 focus:ring-blue-100',
                    )}
                    to={to}
                >
                    <div className="flex items-center">
                        <div className="mr-2.5">{coloredStatusIcon}</div>
                        <div className="font-semibold text-base text-primary-1">
                            {name}
                        </div>
                    </div>
                    <div className="flex justify-end items-center">
                        <div className="text-sm text-neutral-600">
                            {formatDateTime(createdAt)}
                        </div>
                        {showTypeIcon && <div className="ml-2">{typeIcon}</div>}
                    </div>
                </Link>
            </TooltipTriggerV2>
            <TooltipContentV2
                type="primary"
                className="max-w-full text-font-light"
            >
                <SearchResultsTooltipContent
                    {...props}
                    status={status}
                    typeIcon={showTypeIcon ? tooltipTypeIcon : null}
                    statusIcon={statusIcon}
                />
            </TooltipContentV2>
        </TooltipV2>
    );
}
