import {
    AutocompleteV2,
    Button,
    Modal,
    OverlayWithSpinner,
    SelectV2,
} from '_atoms';
import { ArticleSuspicion, SuspicionArticle } from '_types';
import React, { useEffect, useMemo, useState } from 'react';
import { SuspicionBadge } from '../../SuspicionBadge';
import { useTranslation } from 'react-i18next';
import { SuspicionItemStatus } from '_enums';
import {
    RELEVANT_SUSPICION_STATUSES,
    suspicionsWithInvolvementRequired,
} from './constants';
import {
    INVOLVEMENT_OPTIONS,
    SuspicionItemInvolvement,
    SuspicionItemSource,
} from '../../../../../types/SuspicionItem';
import { useToast } from '../../../../../context/Toast';
import { useUpdateSuspicionItemsMutation } from '../../../../../hooks/queries/useUpdateSuspicionItemMutation';
import {
    useCreateSuspicionItemMutation,
    useSupportedSuspicionsQuery,
} from '_queries';
import { useParams } from 'react-router-dom';

interface EditModalProps {
    opened: boolean;
    onOpen: (toggleOpened: boolean) => void;
    suspicions: ArticleSuspicion[];
    activeSuspicionId?: string;
    selectedArticle?: SuspicionArticle;
}

const suspicionStatusOptions = [
    {
        id: 1,
        label: 'suspicionItemStatus.pending',
        value: SuspicionItemStatus.Pending,
    },
    {
        id: 2,
        label: 'suspicionItemStatus.problem',
        value: SuspicionItemStatus.Problem,
    },
    {
        id: 3,
        label: 'suspicionItemStatus.wrong_target',
        value: SuspicionItemStatus.WrongTarget,
    },
    {
        id: 4,
        label: 'suspicionItemStatus.no_problem',
        value: SuspicionItemStatus.NoProblem,
    },
];

const involvementOptions = INVOLVEMENT_OPTIONS.map((option, index) => ({
    id: index + 1,
    label: `suspicionItemInvolvement.${option}`,
    value: option,
}));

export const EditModal = (props: EditModalProps): React.ReactElement => {
    const { opened, onOpen, suspicions, activeSuspicionId, selectedArticle } =
        props;
    const { t } = useTranslation();
    const toast = useToast();

    const { targetId, caseId } =
        useParams<{
            targetId: string;
            caseId: string;
        }>();

    const supportedSuspicionsQuery = useSupportedSuspicionsQuery(caseId);

    const createSuspicionItemMutation = useCreateSuspicionItemMutation();

    const createSuspicionItem = async (
        suspicionId: string,
        involvement?: SuspicionItemInvolvement,
    ): Promise<void> => {
        if (selectedArticle) {
            await createSuspicionItemMutation.mutateAsync({
                targetId,
                entity: selectedArticle.entity.value,
                suspicionIds: [suspicionId],
                involvement,
                status: SuspicionItemStatus.Problem,
                source: SuspicionItemSource.User,
            });
        }
    };

    const updateSuspicionStatusesMutation = useUpdateSuspicionItemsMutation();

    const [selectedSuspicions, setSelectedSuspicions] = useState<Set<string>>(
        new Set<string>(),
    );

    const [suspicionStatus, setSuspicionStatus] =
        useState<SuspicionItemStatus | undefined>(undefined);

    const [updatedSuspicionCount, setUpdatedSuspicionCount] = useState(0);

    const [involvement, setInvolvement] =
        useState<SuspicionItemInvolvement | undefined>(undefined);

    const [supportedSuspicionId, setSupportedSuspicionId] =
        useState<string | undefined>(undefined);

    const [addSuspicionLoading, setAddSuspicionLoading] = useState(false);

    const resetSuspicionItemLink = (): void => {
        setInvolvement(undefined);
        setSupportedSuspicionId(undefined);
    };

    useEffect(() => {
        setSelectedSuspicions(new Set());
        setSuspicionStatus(undefined);
        resetSuspicionItemLink();
        setAddSuspicionLoading(false);
    }, [opened]);

    const updateStatus = async () => {
        const activeSuspicion = suspicions?.find(
            ({ suspicion }) => suspicion.id === activeSuspicionId,
        );
        const shouldCloseModal =
            activeSuspicion?.id &&
            selectedSuspicions.has(activeSuspicion?.id) &&
            suspicionStatus === SuspicionItemStatus.WrongTarget;

        if (suspicionStatus) {
            await updateSuspicionStatusesMutation.mutateAsync({
                itemIds: Array.from(selectedSuspicions.values()),
                status: suspicionStatus,
            });

            setUpdatedSuspicionCount(selectedSuspicions.size);
            toast({
                style: 'confirmation',
                text: t('toasts.suspicionStatusUpdated'),
                ms: 5000,
            });
        }

        setSelectedSuspicions(new Set());
        setSuspicionStatus(undefined);

        if (shouldCloseModal) {
            onOpen(false);
        }
    };

    const onSuspicionSelect = (id: string): void => {
        setSelectedSuspicions((prev) => {
            const newSet = new Set(prev);
            if (prev.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const selectAll = (): void => {
        setSelectedSuspicions(new Set(suspicions?.flatMap(({ id }) => id)));
    };

    const unselectAll = (): void => {
        setSelectedSuspicions(new Set());
    };

    useEffect(() => {
        if (updatedSuspicionCount > 0) {
            setTimeout(() => {
                setUpdatedSuspicionCount(0);
            }, 6000);
        }
    }, [updatedSuspicionCount]);

    const isAnySuspicionSelected = selectedSuspicions.size > 0;

    const handleCreateSuspicionItem = async (): Promise<void> => {
        if (supportedSuspicionId) {
            setAddSuspicionLoading(true);
            await createSuspicionItem(
                supportedSuspicionId,
                involvement,
            ).finally(() => {
                setAddSuspicionLoading(false);
                resetSuspicionItemLink();
                toast({
                    style: 'confirmation',
                    text: t('toasts.suspicionItemLinked'),
                    ms: 5000,
                });
            });
        }
    };

    const existingSuspicionIds = useMemo(
        () => suspicions.flatMap(({ suspicion }) => suspicion.id),
        [suspicions],
    );

    const supportedSuspicionOptions = useMemo(() => {
        return (supportedSuspicionsQuery.data ?? [])
            .filter((suspicion) => !existingSuspicionIds.includes(suspicion.id))
            .map((suspicion) => ({
                label: t(`suspicionKeywords.${suspicion.label}`, {
                    defaultValue: suspicion.name,
                }),
                value: suspicion.id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [supportedSuspicionsQuery?.data, existingSuspicionIds, t]);

    const isInvolvementRequired = useMemo(() => {
        const _selectedSuspicion = (supportedSuspicionsQuery.data ?? []).find(
            (suspicion) => suspicion.id === supportedSuspicionId,
        );

        return suspicionsWithInvolvementRequired.includes(
            _selectedSuspicion?.label ?? '',
        );
    }, [supportedSuspicionId, supportedSuspicionsQuery?.data]);

    return (
        <Modal
            isOpen={opened}
            title={t('riskAnalysis.editSuspicions.title')}
            onClose={() => onOpen(false)}
            maxWidth="max-w-4xl"
            noOverflow={true}
        >
            <div className="text-left">
                <div className="flex items-center justify-between">
                    <div className="font-jost font-bold text-sm text-neutral-500">
                        {t('riskAnalysis.editSuspicions.selectSuspicion')}
                    </div>
                    <Button
                        level="custom"
                        className="text-primary-4"
                        onClick={
                            isAnySuspicionSelected ? unselectAll : selectAll
                        }
                    >
                        {t(
                            `riskAnalysis.editSuspicions.${
                                isAnySuspicionSelected
                                    ? 'unselectAll'
                                    : 'selectAll'
                            }`,
                        )}
                    </Button>
                </div>
                <div className="flex flex-wrap gap-3 flex-1 overflow-y-auto max-h-[90px]">
                    {suspicions?.map(
                        ({ suspicion, id, involvement, status }) => {
                            const isSelected = selectedSuspicions.has(id);

                            return (
                                <SuspicionBadge
                                    key={id}
                                    isSelected={isSelected}
                                    onClick={() => onSuspicionSelect(id)}
                                    name={t(
                                        `suspicionKeywords.${suspicion.label}`,
                                        {
                                            defaultValue: suspicion.name,
                                        },
                                    )}
                                    involvement={
                                        involvement
                                            ? t(
                                                  `suspicionItemInvolvement.${involvement}`,
                                              )
                                            : undefined
                                    }
                                    stripedOut={
                                        status
                                            ? !RELEVANT_SUSPICION_STATUSES.includes(
                                                  status,
                                              )
                                            : false
                                    }
                                    status={status}
                                />
                            );
                        },
                    )}
                </div>
                <div className="flex items-center mt-6">
                    <div className="w-48">
                        <SelectV2
                            label={t(
                                'riskAnalysis.editSuspicions.suspicionStatus',
                            )}
                            options={suspicionStatusOptions}
                            selected={suspicionStatusOptions.find(
                                (option) => option.value === suspicionStatus,
                            )}
                            addEmptyOption={true}
                            onChange={(value) => {
                                setSuspicionStatus(value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-neutral-400 w-full h-[1px] my-5" />

            <OverlayWithSpinner showOverlay={addSuspicionLoading}>
                <div className="font-jost font-bold text-neutral-500 mb-1">
                    {t('riskAnalysis.editSuspicions.addSuspicion')}
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <div className="max-w-100 w-full">
                            <AutocompleteV2
                                options={supportedSuspicionOptions}
                                value={supportedSuspicionId}
                                onChange={setSupportedSuspicionId}
                                placeholder={t(
                                    'riskAnalysis.editSuspicions.searchSuspicion',
                                )}
                                disabled={addSuspicionLoading}
                            />
                        </div>
                        <div className="w-64">
                            <SelectV2
                                options={involvementOptions}
                                selected={involvementOptions.find(
                                    (option) => option.value === involvement,
                                )}
                                addEmptyOption={true}
                                onChange={setInvolvement}
                                disabled={addSuspicionLoading}
                            />
                        </div>
                    </div>
                    <div className="flex items-center">
                        <Button
                            level="custom"
                            className="text-error-1"
                            onClick={resetSuspicionItemLink}
                            disabled={addSuspicionLoading}
                        >
                            {t('riskAnalysis.editSuspicions.reset')}
                        </Button>
                        <Button
                            level="custom"
                            className="text-primary-4"
                            onClick={handleCreateSuspicionItem}
                            disabled={
                                (isInvolvementRequired && !involvement) ||
                                !supportedSuspicionId ||
                                addSuspicionLoading
                            }
                        >
                            {t('riskAnalysis.editSuspicions.addToList')}
                        </Button>
                    </div>
                </div>
            </OverlayWithSpinner>
            <div className="bg-neutral-400 w-full h-[1px] my-5" />
            <div className="flex justify-between items-center">
                <div className="text-success-1 font-jost">
                    {updatedSuspicionCount > 0 &&
                        t(
                            `riskAnalysis.editSuspicions.successMessage.${
                                updatedSuspicionCount === 1
                                    ? 'singular'
                                    : 'plural'
                            }`,
                            { count: updatedSuspicionCount },
                        )}
                </div>
                <div className="flex justify-end gap-5">
                    <Button
                        level="custom"
                        onClick={() => onOpen(false)}
                        className="min-w-30 text-primary-4"
                    >
                        {t('closeButton')}
                    </Button>
                    <Button
                        level="primary"
                        onClick={updateStatus}
                        className="min-w-44"
                        disabled={
                            !selectedSuspicions.size ||
                            !suspicionStatus ||
                            updateSuspicionStatusesMutation.isLoading
                        }
                    >
                        {t('riskAnalysis.editSuspicions.confirm')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
