import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    Paragraph,
    Tag,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { mapSourceToText } from '_utils';

export type InfoItem = Readonly<{
    value: string;
    icon?: React.ReactElement;
    sources?: string[];
    additionalValue?: string;
}>;

interface InfoBlockProps {
    title: string;
    infoList: ReadonlyArray<InfoItem>;
    containerClassName?: string;
}

export const InfoBlock = (props: InfoBlockProps): React.ReactElement => {
    const { title, infoList, containerClassName } = props;
    const { t } = useTranslation();
    const filteredInfoList = infoList.filter(({ value }) => value);

    return (
        <div className={classNames(containerClassName)}>
            <Paragraph size="label" weight="bold" color="light" className="p-1">
                {title}
            </Paragraph>
            {filteredInfoList.length ? (
                filteredInfoList.map(
                    ({ sources = [], value, icon, additionalValue }, index) => (
                        <TooltipV2 key={index} disabled={sources.length === 0}>
                            <TooltipTriggerV2>
                                <div className="group mb-1">
                                    <Paragraph
                                        size="default"
                                        lineHeight="default"
                                        weight="medium"
                                        color="dark"
                                        className={classNames(
                                            'break-words p-1 rounded-md flex items-center',
                                        )}
                                    >
                                        {icon ? (
                                            <span className="mr-2">{icon}</span>
                                        ) : null}
                                        {value + (additionalValue ?? '')}
                                    </Paragraph>
                                </div>
                            </TooltipTriggerV2>
                            <TooltipContentV2>
                                <Paragraph
                                    color="white"
                                    weight="medium"
                                    size="medium"
                                >
                                    {`${t('sources')}:`}
                                </Paragraph>
                                {sources.map((source, index) => (
                                    <Paragraph
                                        color="white"
                                        weight="medium"
                                        size="medium"
                                        key={index}
                                    >
                                        {`- ${t(mapSourceToText(source))}`}
                                    </Paragraph>
                                ))}
                            </TooltipContentV2>
                        </TooltipV2>
                    ),
                )
            ) : (
                <Tag>{t('noData')}</Tag>
            )}
        </div>
    );
};
