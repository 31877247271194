import React from 'react';
import {
    Checkbox as DefaultCheckbox,
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';

type CheckboxProps = {
    value: boolean;
    onChange: (value: boolean) => void;
    label?: string;
    title?: string;
    tooltipContent?: string;
};

export const Checkbox = (props: CheckboxProps): React.ReactElement => {
    const { value, onChange, label, title, tooltipContent } = props;

    return (
        <div className="py-2">
            {title && (
                <Paragraph
                    color="light"
                    size="small"
                    className="uppercase mb-1"
                >
                    {title}
                </Paragraph>
            )}
            <div className="flex items-center">
                <DefaultCheckbox
                    onChange={onChange}
                    label={label}
                    centered={false}
                    labelPosition="append"
                    initialIsSelected={value}
                />
                {tooltipContent && (
                    <TooltipV2 openDelay={500} placement="bottom-start">
                        <TooltipContentV2 className="max-w-72 z-20">
                            {tooltipContent}
                        </TooltipContentV2>
                        <TooltipTriggerV2>
                            <ExclamationIcon className="w-4 h-4 ml-2 flex justify-center items-center text-neutral-500 rotate-180" />
                        </TooltipTriggerV2>
                    </TooltipV2>
                )}
            </div>
        </div>
    );
};
