import { FC } from 'react';
import classnames from 'classnames';
import {
    TableData,
    TableRowAction,
    TableRowActionsMenu,
} from '_organisms/Table';
import { Customer } from '@indicium/common';
import { UserDataWithStats } from 'src/types/UserData';
import { formatDate, formatDateTime } from '_utils';
import { getI18n, useTranslation } from 'react-i18next';
import { TextAlign } from '_organisms/Table/Table';
import {
    EmptyTableCellWithRowBorderRadius,
    rowBorderRadiusClassNames,
} from '_organisms/Table/utils';
import { TooltipContentV2, TooltipTriggerV2, TooltipV2 } from '_atoms';

type UserTableProps = {
    headlines: string[];
    users: Customer[] | UserDataWithStats[];
    tableRowActions?: TableRowAction[];
    layoutOptions: {
        highlightColumns?: {
            firstColBold?: boolean;
            secondColBold?: boolean;
        };
        textAlign?: TextAlign;
    };
    footer?: (number | string | undefined)[];
    editingCustomerUsers?: boolean;
};

const translateItem: {
    [key: string]: boolean;
} = {
    privilegeLevel: true,
};

const isCustomerData = (data: Customer | UserDataWithStats): data is Customer =>
    (data as Customer).name !== undefined;

const prepareUserData = (
    {
        firstname = '',
        lastname = '',
        email,
        privilegeLevel = 'CustomerUser',
        createdAt,
    }: UserDataWithStats,
    editingCustomerUsers: boolean,
) => {
    const { t } = getI18n();
    if (editingCustomerUsers) {
        return {
            name: `${firstname} ${lastname}`,
            email,
            privilegeLevel,
        };
    }
    return {
        name: `${firstname} ${lastname}`,
        email,
        privilegeLevel,
        createdAt: createdAt ? formatDate(createdAt) : t('notAvailable'),
    };
};

const prepareCustomerData = ({
    name,
    reportCount,
    createdAt,
    lastReportCreation,
}: Customer) => {
    const { t } = getI18n();
    return {
        name,
        reportCount,
        lastCreated: lastReportCreation
            ? formatDateTime(lastReportCreation)
            : t('notAvailable'),
        createdAt: createdAt ? formatDate(createdAt) : t('notAvailable'),
    };
};

export const UserTable: FC<UserTableProps> = ({
    headlines,
    users,
    tableRowActions,
    layoutOptions,
    footer,
    editingCustomerUsers = false,
}: UserTableProps) => {
    const { t } = useTranslation();
    const { highlightColumns, textAlign } = layoutOptions;

    const tableHeaderAndFooterClasses = classnames(
        rowBorderRadiusClassNames,
        'text-base leading-4 font-jost font-medium uppercase',
    );

    return (
        <table
            cellPadding="0"
            cellSpacing="0"
            className="w-full p-0 rounded-md border-spacing-y-3 border-separate -mt-3"
        >
            <thead className="text-font-light bg-primary-1">
                <tr className="*:px-7 *:py-5">
                    {headlines.map((header, index) => (
                        <th
                            key={header}
                            className={classnames(
                                tableHeaderAndFooterClasses,
                                textAlign?.[index]
                                    ? `text-${textAlign[index]}`
                                    : 'text-left',
                            )}
                        >
                            {header !== t('totalReports') ? (
                                header
                            ) : (
                                <TooltipV2
                                    openDelay={500}
                                    placement="bottom-start"
                                >
                                    <TooltipContentV2
                                        className="max-w-72 z-20"
                                        type="secondary"
                                    >
                                        {t('includingDeleted')}
                                    </TooltipContentV2>{' '}
                                    <TooltipTriggerV2>
                                        <span className="font-jost">
                                            {header}*
                                        </span>
                                    </TooltipTriggerV2>
                                </TooltipV2>
                            )}
                        </th>
                    ))}
                    {tableRowActions && <EmptyTableCellWithRowBorderRadius />}
                </tr>
            </thead>
            <tbody>
                {users.map((user, index) => (
                    <tr
                        key={user.id}
                        className={classnames(
                            'relative *:px-7 *:py-5',
                            index % 2 === 0
                                ? 'bg-neutral-100'
                                : 'bg-neutral-100',
                        )}
                    >
                        {Object.entries(
                            isCustomerData(user)
                                ? prepareCustomerData(user)
                                : prepareUserData(user, editingCustomerUsers),
                        ).map(([key, item], index) => {
                            const highlightColumn =
                                (highlightColumns?.firstColBold &&
                                    index === 0) ||
                                (highlightColumns?.secondColBold &&
                                    index === 1);
                            return (
                                <TableData
                                    text={
                                        translateItem[key]
                                            ? `${t(`${item}`)}`
                                            : item
                                    }
                                    key={index}
                                    textWeight={
                                        highlightColumn ? 'bold' : 'normal'
                                    }
                                    textColor={
                                        highlightColumn ? 'dark' : 'default'
                                    }
                                    className={classnames(
                                        rowBorderRadiusClassNames,
                                        textAlign?.[index]
                                            ? `text-${textAlign[index]}`
                                            : 'text-left',
                                    )}
                                />
                            );
                        })}
                        {tableRowActions ? (
                            <TableRowActionsMenu
                                actions={tableRowActions}
                                id={user.id as string}
                            />
                        ) : null}
                    </tr>
                ))}
            </tbody>
            {footer && footer.length && (
                <tfoot className="text-font-light bg-primary-1 overflow-hidden">
                    <tr className="*:px-7 *:py-5">
                        {footer.map((v, i) => (
                            <td
                                key={`footer-cell-${i}`}
                                className={classnames(
                                    tableHeaderAndFooterClasses,
                                    typeof v === 'number' && 'text-center',
                                )}
                            >
                                {v}
                            </td>
                        ))}
                        {tableRowActions && (
                            <EmptyTableCellWithRowBorderRadius Tag="td" />
                        )}
                    </tr>
                </tfoot>
            )}
        </table>
    );
};
