import React from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useTooltipContext } from './Tooltip';

interface TooltipTriggerProps extends React.HTMLProps<HTMLElement> {
    asChild?: boolean;
}

export const TooltipTrigger = React.forwardRef<
    HTMLElement,
    TooltipTriggerProps
>(function TooltipTrigger(
    { children, asChild, ...props },
    propRef,
): React.ReactElement {
    const context = useTooltipContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
        return React.cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                'data-state': context.open ? 'open' : 'closed',
            }),
        );
    }

    return (
        <div
            ref={ref}
            // The user can style the trigger based on the state
            data-state={context.open ? 'open' : 'closed'}
            {...context.getReferenceProps(props)}
        >
            {children}
        </div>
    );
});
