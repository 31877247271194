import React, { FC, ReactNode } from 'react';
import {
    Paragraph,
    SizeLevel,
    SourceInfo,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { hasFlag } from 'country-flag-icons';
import { getCode, getName } from 'country-list';
import Flags from 'country-flag-icons/react/1x1';
import {
    LegacyTargetCandidateInfoItem,
    TargetReportSourceType,
} from '@indicium/common';
import { mapSourceToText } from '_utils';

type InfoBlockItemProps = {
    info: LegacyTargetCandidateInfoItem;
    collapsed?: boolean;
    className?: string;
    size?: SizeLevel;
    noDataComponent?: ReactNode;
    onOverflow?: () => void;
};

type InfoBlockItemValuesProps = {
    values: string[];
    sources?: TargetReportSourceType[];
    collapsed?: boolean;
    size?: SizeLevel;
    valuesType?: 'text' | 'url';
};

const InfoBlockItemValues: FC<InfoBlockItemValuesProps> = ({
    values,
    sources = [],
    collapsed,
    size,
    valuesType = 'text',
}: InfoBlockItemValuesProps) => {
    const { t } = useTranslation();

    const itemValues = (value: string) => (
        <Paragraph
            size={size}
            weight="normal"
            lineHeight="default"
            color="dark"
            title={value}
        >
            {valuesType === 'url' ? (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block font-medium text-blue-600 dark:text-blue-500 hover:underline"
                >
                    {value}
                </a>
            ) : (
                <span
                    className={classnames(
                        'block',
                        'text-left',
                        collapsed && 'truncate',
                    )}
                >
                    {value}
                </span>
            )}
        </Paragraph>
    );

    return (
        <>
            {values.map((value, index) => (
                <li key={index} className="relative group">
                    {sources?.length > 0 ? (
                        <TooltipV2 key={index} disabled={false}>
                            <TooltipTriggerV2>
                                {itemValues(value)}
                            </TooltipTriggerV2>
                            <TooltipContentV2>
                                <Paragraph
                                    color="white"
                                    weight="medium"
                                    size="medium"
                                >
                                    {`${t('sources')}:`}
                                </Paragraph>
                                {sources.map((source, index) => (
                                    <Paragraph
                                        color="white"
                                        weight="medium"
                                        size="medium"
                                        key={index}
                                    >
                                        {`- ${t(mapSourceToText(source))}`}
                                    </Paragraph>
                                ))}
                            </TooltipContentV2>
                        </TooltipV2>
                    ) : (
                        itemValues(value)
                    )}
                </li>
            ))}
        </>
    );
};

const NationalityInfoBlockItemValues: FC<InfoBlockItemValuesProps> = ({
    values,
    sources = [],
    size,
}: InfoBlockItemValuesProps) => (
    <>
        {values.map((originalValue, index) => {
            // special corner case (see https://en.wikipedia.org/wiki/ISO_3166-1#Coding for more info)
            const value =
                originalValue === 'United Kingdom' ? 'GB' : originalValue;
            const isoCode = hasFlag(value) ? value : getCode(value);
            const Flag = isoCode && (Flags as any)[isoCode];
            const country = isoCode ? getName(isoCode) : value;

            return (
                <li key={index} className="relative group">
                    <Paragraph
                        size={size}
                        weight="normal"
                        lineHeight="default"
                        color="dark"
                        title={value}
                        className="flex relative group"
                    >
                        <span className="w-6">
                            {Flag && <Flag className="w-5 h-5 rounded-full" />}
                        </span>
                        {country}
                        {sources.length > 0 && (
                            <div className="p-1 absolute opacity-0 group-hover:opacity-100 bg-gray-100 top-5 w-full z-10 rounded-b-md">
                                <SourceInfo color="dark" sources={sources} />
                            </div>
                        )}
                    </Paragraph>
                </li>
            );
        })}
    </>
);

const customInfoBlockValueComponents: Record<string, FC<any>> = {
    nationality: NationalityInfoBlockItemValues,
    default: InfoBlockItemValues,
};

export const InfoBlockItem: FC<InfoBlockItemProps> = ({
    info: { key, values, sources },
    className,
    onOverflow,
    collapsed = false,
    size = 'default',
    noDataComponent,
}: InfoBlockItemProps) => {
    const { t } = useTranslation();
    const displayedValues = collapsed ? values.slice(0, 6) : values;
    const sortedValues = displayedValues.slice().sort();

    const detectOverflow = (ref: HTMLSpanElement) => {
        if (!onOverflow || !ref) {
            return;
        }
        const isOverflowing = ref.scrollWidth > ref.clientWidth;
        if (isOverflowing) {
            onOverflow();
        }
    };

    const props = {
        values: sortedValues,
        sources,
        collapsed,
        size,
        handleOverflow: detectOverflow,
    };

    const valuesComponentKey =
        !key || !customInfoBlockValueComponents[key] ? 'default' : key;
    const _ValuesComponent = customInfoBlockValueComponents[valuesComponentKey];

    return (
        <div className={className}>
            {key && (
                <Paragraph size="label" weight="bold">
                    {t(`infoBlockItem.${key}`)}
                </Paragraph>
            )}
            <ul
                className={`${
                    key == 'url' ? 'break-all' : 'break-words'
                }  space-y-1`}
            >
                {!key || !customInfoBlockValueComponents[key] ? (
                    <InfoBlockItemValues
                        valuesType={
                            key == 'url' || key === 'websites' ? 'url' : 'text'
                        }
                        {...props}
                    />
                ) : (
                    <NationalityInfoBlockItemValues {...props} />
                )}
                {displayedValues.length === 0 && noDataComponent}
            </ul>
        </div>
    );
};
